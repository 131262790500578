import {createBrowserRouter} from 'react-router-dom';
import {Layout} from './components/Layout';
import {ErrorHandler} from './components/ErrorHandler';
import {
  LazyApiStore,
  LazyBlog,
  LazyBlogPost,
  LazyContact,
  LazyDocumentation,
  LazyHome,
  LazyOnlineApiTest,
  LazyErrorPage,
  LazyPricing,
  LazyRawHtmlScraper,
  LazySearchEngineScraper,
  LazyEcommerceScraper,
  LazyRealEstateScraper,
  LazyLinkedInScraper,
  LazyInstagramScraper,
  LazyFacebookScraper,
  LazyTiktokScraper,
  LazyThreadsScraper,
  LazyTwitterScraper,
  LazyYieldbooking,
  LazyPrestashop,
  LazyFundaScraper,
  LazyCdiscountScraper,
  LazyAmazonScraper,
  LazyRakutenScraper,
  LazyFnacScraper,
  LazySephoraScraper,
  LazyLeboncoinScraper,
  LazyWoodbrassScraper,
  LazyFossilScraper,
  LazySubitoScraper,
  LazyBestbuyScraper,
  LazyZaraScraper,
  LazyGogScraper,
  LazyMaisonDuMondeScraper,
  LazyImmobilienscoutScraper,
  LazyDellScraper,
  LazyBoseScraper,
  LazyBoulangerScraper,
  LazyBolScraper,
  LazyLaRedouteScraper,
  LazyRightmoveScraper,
  LazyHardwareScraper,
  LazyIdealistaScraper,
  LazyImmowebScraper,
  LazyBienIciScraper,
  LazySeLogerScraper,
  LazyKaufpartsScraper,
  LazyCraigslistScraper,
  LazyIGVaultScraper,
  LazyEntreParticuliersScraper,
  LazyMaterielNetScraper,
  LazyEbayScraper,
  LazyAliexpressScraper,
  LazyFacebookPostScraper,
  LazyFacebookJobsScraper,
  LazyFacebookBusinessPageScraper,
  LazyFacebookProfileScraper,
  LazyLinkedInPostScraper,
  LazyLinkedInBusinessProfilesScraper,
  LazyLinkedinUrlsScraper,
  LazyLinkedinProfileScraper,
  LazyInstagramPostScraper,
  LazyInstagramProfilesScraper,
  LazyTiktokProfilesScraper,
  LazyTiktokHashtagsScraper,
  LazyEmailScraping,
  LazyPricingWeb,
  LazyHowToScrapeThreads,
  LazyScrapingBotRealEstate,
  LazyBestWebScrapingTools,
  LazyHowToScrapeTwitter,
  LazyDataSportsBetting,
  LazyTravelScraper,
  LazyAdidasScraper,
  LazyNikeScraper,
  LazyPapScraper,
  LazyPropertyPassBookScraper,
  LazyZillowScraper,
  LazyParuVenduScraper,
  LazyTruliaScraper,
  LazyRealtorScraper,
  LazyImmoDiffusionScraper,
  LazyApartmentsScraper,
  LazyOuestfranceImmoScraper,
  LazyAbritelScraper,
  LazyExpediaScraper,
  LazyAirbnbScraper,
  LazyHotelsScraper,
  LazyGitesDeFranceScraper,
  LazyBookingScraper,
  LazyInstantGamingScraper,
  LazyAorusScraper,
  LazyMacwayScraper,
  LazyShopbopScraper,
  LazyMicromaniaScraper,
  LazyPixmaniaScraper,
  LazyZalandoScraper,
  LazyWalmartScraper,
  LazyCultureVeloScraper,
  LazyConforamaScraper,
  LazyNikonScraper,
  LazyPlaymobilScraper,
  LazyLambdaTekScraper,
  LazyCanadianTireScraper,
  LazyDysonScraper,
  LazyCartersScraper,
  LazyBrownsFashionScraper,
  LazyNetAPorterScraper,
  LazyNeimanMarcusScraper,
  LazyJohnLewisScraper,
  LazySheinScraper,
  LazyG2AScraper,
  LazylacompagniedurhumScraper,
  LazyBonsPlansEasycashScraper,
  LazyTopachatScraper,
  LazyRueDuCommerceScraper,
  LazyJoueclubScraper,
  LazyLoisirs3000Scraper,
  LazyFarfetchScraper,
  LazyEpsonScraper,
  LazyJdSportsScraper,
  LazyAlibabaScraper,
  LazyPlaystationScraper,
  LazyTrianglScraper,
  LazyBmxshopScraper,
  LazySsenseScraper,
  LazyUniversalScrapers,
  LazyFaq,
  LazyHowToScrapeAmazon,
} from './components/lazy/LazyComponents';

import {
  ApiDetailsLink,
  ApiStoreLink,
  BASENAME,
  BlogLink,
  BlogPostLink,
  ContactLink,
  OnlineApiTestLink,
  PricingLink,
  DocumentationLink,
  RAW_HTML_LINK,
  SEARCH_ENGINE_LINK,
  ECOMMERCE_LINK,
  REAL_ESTATE_LINK,
  LINKEDIN_LINK,
  INSTAGRAM_LINK,
  FACEBOOK_LINK,
  TIKTOK_LINK,
  THREADS_LINK,
  TWITTER_LINK,
  YIELDBOOKING_LINK,
  PRESTASHOP_LINK,
  FUNDA_LINK,
  CDISCOUNT_LINK,
  AMAZON_LINK,
  RAKUTEN_LINK,
  FNAC_LINK,
  SEPHORA_LINK,
  LEBONCOIN_LINK,
  WOODBRASS_LINK,
  FOSSIL_LINK,
  SUBITO_LINK,
  BESTBUY_LINK,
  ZARA_LINK,
  GOG_LINK,
  MAISONDUMONDE_LINK,
  IMMOBILIENSCOUT_LINK,
  DELL_LINK,
  BOSE_LINK,
  BOULANGER_LINK,
  BOL_LINK,
  LAREDOUTE_LINK,
  RIGHTMOVE_LINK,
  HARDWARE_LINK,
  IDEALISTA_LINK,
  IMMOWEB_LINK,
  BIENICI_LINK,
  SELOGER_LINK,
  KAUFPARTS_LINK,
  CRAIGSLIST_LINK,
  IGVAULT_LINK,
  ENTREPARTICULIERS_LINK,
  MATERIELNET_LINK,
  EBAY_LINK,
  ALIEXPRESS_LINK,
  FACEBOOK_POST_LINK,
  FACEBOOK_JOB_OFFERS_LINK,
  FACEBOOK_BUSINESS_PAGE_LINK,
  FACEBOOK_PROFILE_LINK,
  LINKEDIN_POST_LINK,
  LINKEDIN_BUSINESS_PROFILES_LINK,
  LINKEDIN_URLS_LINK,
  LINKEDIN_PROFILE_LINK,
  INSTAGRAM_POST_LINK,
  INSTAGRAM_PROFILES_LINK,
  TIKTOK_PROFILES_LINK,
  TIKTOK_HASHTAGS_LINK,
  EMAIL_SCRAPING_POST_LINK,
  ECOMMERCE_POST_LINK,
  HOW_TO_SCRAPE_THREADS_POST_LINK,
  REAL_ESTATE_POST_LINK,
  BEST_FREE_SCRAPING_TOOLS_POST_LINK,
  HOW_TO_SCRAPE_TWITTER_POST_LINK,
  SPORTS_BETTING_POST_LINK,
  TRAVEL_LINK,
  ADIDAS_LINK,
  NIKE_LINK,
  PAP_LINK,
  PROPERTY_PASS_BOOK_LINK,
  ZILLOW_LINK,
  PARUVENDU_LINK,
  TRULIA_LINK,
  REALTOR_LINK,
  IMMO_DIFFUSION_LINK,
  APARTMENTS_LINK,
  OUESTFRANCE_IMMO_LINK,
  ABRITEL_LINK,
  EXPEDIA_LINK,
  AIRBNB_LINK,
  HOTELS_LINK,
  GITES_DE_FRANCE_LINK,
  BOOKING_LINK,
  INSTANT_GAMING_LINK,
  AORUS_LINK,
  MACWAY_LINK,
  SHOPBOP_LINK,
  MICROMANIA_LINK,
  PIXMANIA_LINK,
  ZALANDO_LINK,
  WALMART_LINK,
  CULTURE_VELO_LINK,
  CONFORAMA_LINK,
  NIKON_LINK,
  PLAYMOBIL_LINK,
  LAMBDA_TEK_LINK,
  CANADIAN_TIRE_LINK,
  DYSON_LINK,
  CARTERS_LINK,
  BROWNS_FASHION_LINK,
  NET_A_PORTER_LINK,
  NEIMAN_MARCUS_LINK,
  JOHN_LEWIS_LINK,
  SHEIN_LINK,
  G2A_LINK,
  LA_COMPAGNIE_DU_RHUM_LINK,
  BONS_PLANS_EASYCASH_LINK,
  TOPACHAT_LINK,
  RUE_DU_COMMERCE_LINK,
  JOUECLUB_LINK,
  LOISIRS3000_LINK,
  FARFETCH_LINK,
  EPSON_LINK,
  JDSPORTS_LINK,
  ALIBABA_LINK,
  PLAYSTATION_LINK,
  TRIANGL_LINK,
  BMXSHOP_LINK,
  SSENSE_LINK,
  UniversalScrapersLink,
  FaqLink,
  HOW_TO_SCRAPE_AMAZON_POST_LINK,
} from './utils/constants';

export const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Layout />,
      errorElement: <ErrorHandler />,
      children: [
        {
          path: '/',
          element: <LazyHome />,
        },
        {
          path: ApiStoreLink,
          element: <LazyApiStore />,
        },
        {
          path: FaqLink,
          element: <LazyFaq />,
        },
        {
          path: UniversalScrapersLink,
          element: <LazyUniversalScrapers />,
        },
        {
          path: ApiDetailsLink(TIKTOK_HASHTAGS_LINK),
          element: <LazyTiktokHashtagsScraper />,
        },
        {
          path: ApiDetailsLink(TIKTOK_PROFILES_LINK),
          element: <LazyTiktokProfilesScraper />,
        },
        {
          path: ApiDetailsLink(INSTAGRAM_PROFILES_LINK),
          element: <LazyInstagramProfilesScraper />,
        },
        {
          path: ApiDetailsLink(INSTAGRAM_POST_LINK),
          element: <LazyInstagramPostScraper />,
        },
        {
          path: ApiDetailsLink(TRAVEL_LINK),
          element: <LazyTravelScraper />,
        },
        {
          path: ApiDetailsLink(ADIDAS_LINK),
          element: <LazyAdidasScraper />,
        },
        {
          path: ApiDetailsLink(NIKE_LINK),
          element: <LazyNikeScraper />,
        },
        {
          path: ApiDetailsLink(PAP_LINK),
          element: <LazyPapScraper />,
        },
        {
          path: ApiDetailsLink(PROPERTY_PASS_BOOK_LINK),
          element: <LazyPropertyPassBookScraper />,
        },
        {
          path: ApiDetailsLink(ZILLOW_LINK),
          element: <LazyZillowScraper />,
        },
        {
          path: ApiDetailsLink(PARUVENDU_LINK),
          element: <LazyParuVenduScraper />,
        },
        {
          path: ApiDetailsLink(TRULIA_LINK),
          element: <LazyTruliaScraper />,
        },
        {
          path: ApiDetailsLink(REALTOR_LINK),
          element: <LazyRealtorScraper />,
        },
        {
          path: ApiDetailsLink(IMMO_DIFFUSION_LINK),
          element: <LazyImmoDiffusionScraper />,
        },
        {
          path: ApiDetailsLink(APARTMENTS_LINK),
          element: <LazyApartmentsScraper />,
        },
        {
          path: ApiDetailsLink(OUESTFRANCE_IMMO_LINK),
          element: <LazyOuestfranceImmoScraper />,
        },
        {
          path: ApiDetailsLink(ABRITEL_LINK),
          element: <LazyAbritelScraper />,
        },
        {
          path: ApiDetailsLink(EXPEDIA_LINK),
          element: <LazyExpediaScraper />,
        },
        {
          path: ApiDetailsLink(AIRBNB_LINK),
          element: <LazyAirbnbScraper />,
        },
        {
          path: ApiDetailsLink(HOTELS_LINK),
          element: <LazyHotelsScraper />,
        },
        {
          path: ApiDetailsLink(GITES_DE_FRANCE_LINK),
          element: <LazyGitesDeFranceScraper />,
        },
        {
          path: ApiDetailsLink(BOOKING_LINK),
          element: <LazyBookingScraper />,
        },
        {
          path: ApiDetailsLink(INSTANT_GAMING_LINK),
          element: <LazyInstantGamingScraper />,
        },
        {
          path: ApiDetailsLink(AORUS_LINK),
          element: <LazyAorusScraper />,
        },
        {
          path: ApiDetailsLink(MACWAY_LINK),
          element: <LazyMacwayScraper />,
        },
        {
          path: ApiDetailsLink(SHOPBOP_LINK),
          element: <LazyShopbopScraper />,
        },
        {
          path: ApiDetailsLink(MICROMANIA_LINK),
          element: <LazyMicromaniaScraper />,
        },
        {
          path: ApiDetailsLink(PIXMANIA_LINK),
          element: <LazyPixmaniaScraper />,
        },
        {
          path: ApiDetailsLink(ZALANDO_LINK),
          element: <LazyZalandoScraper />,
        },
        {
          path: ApiDetailsLink(WALMART_LINK),
          element: <LazyWalmartScraper />,
        },
        {
          path: ApiDetailsLink(CULTURE_VELO_LINK),
          element: <LazyCultureVeloScraper />,
        },
        {
          path: ApiDetailsLink(CONFORAMA_LINK),
          element: <LazyConforamaScraper />,
        },
        {
          path: ApiDetailsLink(NIKON_LINK),
          element: <LazyNikonScraper />,
        },
        {
          path: ApiDetailsLink(PLAYMOBIL_LINK),
          element: <LazyPlaymobilScraper />,
        },
        {
          path: ApiDetailsLink(LAMBDA_TEK_LINK),
          element: <LazyLambdaTekScraper />,
        },
        {
          path: ApiDetailsLink(CANADIAN_TIRE_LINK),
          element: <LazyCanadianTireScraper />,
        },
        {
          path: ApiDetailsLink(DYSON_LINK),
          element: <LazyDysonScraper />,
        },
        {
          path: ApiDetailsLink(CARTERS_LINK),
          element: <LazyCartersScraper />,
        },
        {
          path: ApiDetailsLink(BROWNS_FASHION_LINK),
          element: <LazyBrownsFashionScraper />,
        },
        {
          path: ApiDetailsLink(NET_A_PORTER_LINK),
          element: <LazyNetAPorterScraper />,
        },
        {
          path: ApiDetailsLink(NEIMAN_MARCUS_LINK),
          element: <LazyNeimanMarcusScraper />,
        },
        {
          path: ApiDetailsLink(JOHN_LEWIS_LINK),
          element: <LazyJohnLewisScraper />,
        },
        {
          path: ApiDetailsLink(SHEIN_LINK),
          element: <LazySheinScraper />,
        },
        {
          path: ApiDetailsLink(G2A_LINK),
          element: <LazyG2AScraper />,
        },
        {
          element: <LazylacompagniedurhumScraper />,
          path: ApiDetailsLink(LA_COMPAGNIE_DU_RHUM_LINK),
        },
        {
          element: <LazyBonsPlansEasycashScraper />,
          path: ApiDetailsLink(BONS_PLANS_EASYCASH_LINK),
        },
        {
          element: <LazyTopachatScraper />,
          path: ApiDetailsLink(TOPACHAT_LINK),
        },
        {
          element: <LazyRueDuCommerceScraper />,
          path: ApiDetailsLink(RUE_DU_COMMERCE_LINK),
        },
        {
          element: <LazyJoueclubScraper />,
          path: ApiDetailsLink(JOUECLUB_LINK),
        },
        {
          element: <LazyLoisirs3000Scraper />,
          path: ApiDetailsLink(LOISIRS3000_LINK),
        },
        {
          element: <LazyJdSportsScraper />,
          path: ApiDetailsLink(JDSPORTS_LINK),
        },
        {
          element: <LazyFarfetchScraper />,
          path: ApiDetailsLink(FARFETCH_LINK),
        },
        {
          element: <LazyEpsonScraper />,
          path: ApiDetailsLink(EPSON_LINK),
        },
        {
          element: <LazyAlibabaScraper />,
          path: ApiDetailsLink(ALIBABA_LINK),
        },
        {
          element: <LazyPlaystationScraper />,
          path: ApiDetailsLink(PLAYSTATION_LINK),
        },
        {
          element: <LazyTrianglScraper />,
          path: ApiDetailsLink(TRIANGL_LINK),
        },
        {
          element: <LazyBmxshopScraper />,
          path: ApiDetailsLink(BMXSHOP_LINK),
        },
        {
          element: <LazySsenseScraper />,
          path: ApiDetailsLink(SSENSE_LINK),
        },
        {
          path: ApiDetailsLink(LINKEDIN_PROFILE_LINK),
          element: <LazyLinkedinProfileScraper />,
        },
        {
          path: ApiDetailsLink(LINKEDIN_URLS_LINK),
          element: <LazyLinkedinUrlsScraper />,
        },
        {
          path: ApiDetailsLink(LINKEDIN_BUSINESS_PROFILES_LINK),
          element: <LazyLinkedInBusinessProfilesScraper />,
        },
        {
          path: ApiDetailsLink(LINKEDIN_POST_LINK),
          element: <LazyLinkedInPostScraper />,
        },
        {
          path: ApiDetailsLink(FACEBOOK_PROFILE_LINK),
          element: <LazyFacebookProfileScraper />,
        },
        {
          path: ApiDetailsLink(FACEBOOK_BUSINESS_PAGE_LINK),
          element: <LazyFacebookBusinessPageScraper />,
        },
        {
          path: ApiDetailsLink(FACEBOOK_JOB_OFFERS_LINK),
          element: <LazyFacebookJobsScraper />,
        },
        {
          path: ApiDetailsLink(FACEBOOK_POST_LINK),
          element: <LazyFacebookPostScraper />,
        },
        {
          path: ApiDetailsLink(ALIEXPRESS_LINK),
          element: <LazyAliexpressScraper />,
        },
        {
          path: ApiDetailsLink(EBAY_LINK),
          element: <LazyEbayScraper />,
        },
        {
          path: ApiDetailsLink(MATERIELNET_LINK),
          element: <LazyMaterielNetScraper />,
        },
        {
          path: ApiDetailsLink(ENTREPARTICULIERS_LINK),
          element: <LazyEntreParticuliersScraper />,
        },
        {
          path: ApiDetailsLink(IGVAULT_LINK),
          element: <LazyIGVaultScraper />,
        },
        {
          path: ApiDetailsLink(CRAIGSLIST_LINK),
          element: <LazyCraigslistScraper />,
        },
        {
          path: ApiDetailsLink(KAUFPARTS_LINK),
          element: <LazyKaufpartsScraper />,
        },
        {
          path: ApiDetailsLink(SELOGER_LINK),
          element: <LazySeLogerScraper />,
        },
        {
          path: ApiDetailsLink(BIENICI_LINK),
          element: <LazyBienIciScraper />,
        },
        {
          path: ApiDetailsLink(IMMOWEB_LINK),
          element: <LazyImmowebScraper />,
        },
        {
          path: ApiDetailsLink(IDEALISTA_LINK),
          element: <LazyIdealistaScraper />,
        },
        {
          path: ApiDetailsLink(HARDWARE_LINK),
          element: <LazyHardwareScraper />,
        },
        {
          path: ApiDetailsLink(RIGHTMOVE_LINK),
          element: <LazyRightmoveScraper />,
        },
        {
          path: ApiDetailsLink(LAREDOUTE_LINK),
          element: <LazyLaRedouteScraper />,
        },
        {
          path: ApiDetailsLink(BOL_LINK),
          element: <LazyBolScraper />,
        },
        {
          path: ApiDetailsLink(BOULANGER_LINK),
          element: <LazyBoulangerScraper />,
        },
        {
          path: ApiDetailsLink(BOSE_LINK),
          element: <LazyBoseScraper />,
        },
        {
          path: ApiDetailsLink(DELL_LINK),
          element: <LazyDellScraper />,
        },
        {
          path: ApiDetailsLink(IMMOBILIENSCOUT_LINK),
          element: <LazyImmobilienscoutScraper />,
        },
        {
          path: ApiDetailsLink(MAISONDUMONDE_LINK),
          element: <LazyMaisonDuMondeScraper />,
        },
        {
          path: ApiDetailsLink(GOG_LINK),
          element: <LazyGogScraper />,
        },
        {
          path: ApiDetailsLink(ZARA_LINK),
          element: <LazyZaraScraper />,
        },
        {
          path: ApiDetailsLink(BESTBUY_LINK),
          element: <LazyBestbuyScraper />,
        },
        {
          path: ApiDetailsLink(SUBITO_LINK),
          element: <LazySubitoScraper />,
        },
        {
          path: ApiDetailsLink(FOSSIL_LINK),
          element: <LazyFossilScraper />,
        },
        {
          path: ApiDetailsLink(WOODBRASS_LINK),
          element: <LazyWoodbrassScraper />,
        },
        {
          path: ApiDetailsLink(LEBONCOIN_LINK),
          element: <LazyLeboncoinScraper />,
        },
        {
          path: ApiDetailsLink(SEPHORA_LINK),
          element: <LazySephoraScraper />,
        },
        {
          path: ApiDetailsLink(FNAC_LINK),
          element: <LazyFnacScraper />,
        },
        {
          path: ApiDetailsLink(RAKUTEN_LINK),
          element: <LazyRakutenScraper />,
        },
        {
          path: ApiDetailsLink(AMAZON_LINK),
          element: <LazyAmazonScraper />,
        },
        {
          path: ApiDetailsLink(CDISCOUNT_LINK),
          element: <LazyCdiscountScraper />,
        },
        {
          path: ApiDetailsLink(FUNDA_LINK),
          element: <LazyFundaScraper />,
        },
        {
          path: ApiDetailsLink(PRESTASHOP_LINK),
          element: <LazyPrestashop />,
        },
        {
          path: ApiDetailsLink(YIELDBOOKING_LINK),
          element: <LazyYieldbooking />,
        },
        {
          path: ApiDetailsLink(TWITTER_LINK),
          element: <LazyTwitterScraper />,
        },
        {
          path: ApiDetailsLink(THREADS_LINK),
          element: <LazyThreadsScraper />,
        },
        {
          path: ApiDetailsLink(TIKTOK_LINK),
          element: <LazyTiktokScraper />,
        },
        {
          path: ApiDetailsLink(FACEBOOK_LINK),
          element: <LazyFacebookScraper />,
        },
        {
          path: ApiDetailsLink(INSTAGRAM_LINK),
          element: <LazyInstagramScraper />,
        },
        {
          path: ApiDetailsLink(LINKEDIN_LINK),
          element: <LazyLinkedInScraper />,
        },
        {
          path: ApiDetailsLink(REAL_ESTATE_LINK),
          element: <LazyRealEstateScraper />,
        },
        {
          path: ApiDetailsLink(ECOMMERCE_LINK),
          element: <LazyEcommerceScraper />,
        },
        {
          path: ApiDetailsLink(SEARCH_ENGINE_LINK),
          element: <LazySearchEngineScraper />,
        },
        {
          path: ApiDetailsLink(RAW_HTML_LINK),
          element: <LazyRawHtmlScraper />,
        },
        {
          path: BlogLink,
          element: <LazyBlog />,
        },
        {
          path: BlogPostLink(SPORTS_BETTING_POST_LINK),
          element: <LazyDataSportsBetting />,
        },
        {
          path: BlogPostLink(HOW_TO_SCRAPE_TWITTER_POST_LINK),
          element: <LazyHowToScrapeTwitter />,
        },
        {
          path: BlogPostLink(BEST_FREE_SCRAPING_TOOLS_POST_LINK),
          element: <LazyBestWebScrapingTools />,
        },
        {
          path: BlogPostLink(REAL_ESTATE_POST_LINK),
          element: <LazyScrapingBotRealEstate />,
        },
        {
          path: BlogPostLink(HOW_TO_SCRAPE_THREADS_POST_LINK),
          element: <LazyHowToScrapeThreads />,
        },
        {
          path: BlogPostLink(ECOMMERCE_POST_LINK),
          element: <LazyPricingWeb />,
        },
        {
          path: BlogPostLink(EMAIL_SCRAPING_POST_LINK),
          element: <LazyEmailScraping />,
        },
        {
          path: BlogPostLink(HOW_TO_SCRAPE_AMAZON_POST_LINK),
          element: <LazyHowToScrapeAmazon />,
        },
        {
          path: BlogPostLink(':postUrl'),
          element: <LazyBlogPost />,
        },
        {
          path: OnlineApiTestLink,
          element: <LazyOnlineApiTest />,
        },
        {
          path: DocumentationLink(':sectionPage?'),
          element: <LazyDocumentation />,
        },
        {
          path: ContactLink,
          element: <LazyContact />,
        },
        {
          path: PricingLink,
          element: <LazyPricing />,
        },
      ],
    },
    {
      path: 'error',
      element: <LazyErrorPage />,
    },
  ],
  {
    basename: BASENAME,
  }
);
